<template>
  <div class="commodity-detail">
    <div class="main">
      <van-form @submit="onSubmit"
                @failed="onFail">
        <van-field v-model="detail.name"
                   name="物品名称"
                   required
                   label="物品名称"
                   placeholder="请填写"
                   :rules="[{ required: true, message: '物品名称不能为空' }]" />
        <van-field v-model="detail.barcode"
                   name="物品条码"
                   required
                   label="物品条码"
                   placeholder="请填写"
                   :rules="[{ required: true, message: '物品条码不能为空' }]">
          <template #button>
            <div style="font-size:20px">
              <van-icon name="scan"
                        @click.stop="scanAdd" />
            </div>
          </template>
        </van-field>
        <div>
          <van-field v-model="detail.stockItemCategory"
                     name="物品类别"
                     required
                     label="物品类别"
                     clickable
                     readonly
                     @click="showCommodityType = true"
                     placeholder="请选择"
                     :rules="[{ required: true, message: '物品类别不能为空' }]" />
          <van-popup v-model="showCommodityType"
                     round
                     position="bottom">
            <van-radio-group v-model="commodityType.id"
                             @change="radioChange">
              <custom-popup title="物品类别"
                            :columns="commodityTypeColumns"
                            value-key="name"
                            @cancel="commodityTypeCancel"
                            @confirm="commodityTypeConfirm"
                            :select-icon="false">
                <template #option="{option}">
                  <div class="commodity-type">
                    <van-radio :name="option.id"
                               v-if="option.name">{{ option.name }}</van-radio>
                  </div>
                </template>
              </custom-popup>
            </van-radio-group>
          </van-popup>
        </div>

        <van-field v-model="detail.specifications"
                   name="规格型号"
                   label="规格型号"
                   placeholder="请填写" />
        <van-field v-model="detail.manufacturer"
                   name="生产厂家"
                   label="生产厂家"
                   placeholder="请填写" />
        <div>
          <van-field v-model="detail.measuringUnit"
                     name="计量单位"
                     required
                     clickable
                     readonly
                     label="计量单位"
                     placeholder="请选择"
                     @click="showUnit = true"
                     :rules="[{ required: true, message: '计量单位不能为空' }]" />
          <van-popup v-model="showUnit"
                     round
                     position="bottom">
            <custom-popup value-key="value"
                          title="计量单位"
                          :columns="unitColumns"
                          @cancel="showUnit = false"
                          @confirm="unitConfirm"></custom-popup>
          </van-popup>
        </div>
        <van-field v-model="detail.unitPrice"
                   name="物品价格"
                   required
                   label="物品价格"
                   type="number"
                   placeholder="请填写"
                   :rules="[{ required: true, message: '物品价格不能为空' }]" />
        <div>
          <van-field v-model="detail.warehouse"
                     clickable
                     readonly
                     name="首选仓库"
                     label="首选仓库"
                     placeholder="请选择"
                     @click="showWarehouse = true" />
          <van-popup v-model="showWarehouse"
                     round
                     position="bottom">
            <custom-popup title="仓库"
                          value-key="name"
                          :columns="warehouseColumns"
                          @cancel="showWarehouse = false"
                          @confirm="warehouseConfirm"></custom-popup>
          </van-popup>
        </div>
        <van-field v-model="detail.inventoryLowerLimit"
                   name="库存预警值"
                   required
                   label="库存预警值"
                   type="number"
                   placeholder="请填写"
                   :rules="[{ required: true, message: '库存预警值不能为空' }]" />
        <!-- <van-field v-model="detail.inventoryUpperLimit"
                   name="库存上限"
                   required
                   label="库存上限"
                   type="number"
                   placeholder="请填写"
                   :rules="[{ required: true, message: '库存上限不能为空' }]" /> -->
        <div>
          <van-field v-model="detail.statusLabel"
                     clickable
                     readonly
                     name="状态"
                     label="状态"
                     placeholder="请选择"
                     @click="showStatus = true" />
          <van-popup v-model="showStatus"
                     round
                     position="bottom">
            <custom-popup title="状态"
                          value-key="label"
                          :columns="statusColumns"
                          @cancel="showStatus = false"
                          @confirm="statusConfirm"></custom-popup>
          </van-popup>
        </div>

        <van-field v-model="detail.remark"
                   name="备注"
                   label="备注"
                   placeholder="请填写" />

        <van-field name="附件"
                   label-width="60"
                   label="附件"
                   placeholder="请填写">
          <van-uploader class="item-uploader"
                        slot="input"
                        v-model="detail.attachments"
                        :before-read="beforeRead"
                        :preview-options="{ closeable: true, images: detail.attachments.map(item => item.url) }"
                        :after-read="afterRead"
                        :before-delete="beforeDelete">
            <template #preview-cover="file">
              <van-checkbox class="cover-checkbox"
                            @click.stop.native="handleCoverCheckboxClick(file)"
                            v-model="file.isCover"
                            icon-size="18px">封面图</van-checkbox>
            </template>
          </van-uploader>
        </van-field>

        <div class="action">
          <van-button class="save"
                      round
                      block
                      type="info"
                      native-type="submit">保存</van-button>
          <van-button class="delete"
                      round
                      block
                      type="danger"
                      native-type="button"
                      @click="deleteCommodity"
                      v-if="$route.params.id">删除</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/commodity/commodityDetail.less";
import { SUCCESS_CODE } from "@/apis/notification";
import { flatMap, find, pick, assign } from "lodash-es";
import Filter from "ldap-filters";
import wxapi from "@/lib/wechat";
export default {
  name: "CommodityDetail",
  data() {
    return {
      isCover: "",
      detail: {
        name: "",
        barcode: "",
        stockItemCategoryId: "",
        stockItemCategory: "",
        manufacturer: "",
        measuringUnit: "",
        unitPrice: "",
        warehouseId: "",
        warehouse: "",
        inventoryLowerLimit: "",
        inventoryUpperLimit: "",
        status: "ENABLE",
        statusLabel: "启用",
        remark: "",
        attachments: []
      },
      showCommodityType: false,
      commodityTypeColumns: [],
      commodityType: {
        id: "",
        text: ""
      },

      showUnit: false,
      unitColumns: [],

      showWarehouse: false,
      warehouseColumns: [],

      showStatus: false,
      statusColumns: [
        { label: "启用", value: "ENABLE" },
        { label: "禁用", value: "DISABLE" },
        { label: "删除", value: "DELETED" }
      ]
    };
  },
  methods: {
    async getStockItemType() {
      let ret = await this.$api.getStockItemType();
      if (ret.code != SUCCESS_CODE) return;
      let data = ret.data;
      data.forEach(item => {
        if (item.stockItemCategorys.length) {
          item.children = item.stockItemCategorys;
        } else {
          item.children = [{ name: "", id: "" }];
        }
      });
      this.commodityTypeColumns = data;
      this.flatCommodityType = flatMap(data, item => {
        return [item, ...item.stockItemCategorys];
      });
      if (this.$route.params.id) {
        let item = find(this.flatCommodityType, item => item.id == this.detail.stockItemCategoryId);
        this.detail.stockItemCategory = item.name;
      }
    },
    getStockItemByBarCode(barcode) {
      return this.$api.getStockItemByBarCode({
        params: {
          barCode: barcode
        }
      });
    },
    getStockItemList({ barcode }) {
      let params = {
        page: false
      };
      let query = [{ key: "status", value: "ENABLE", oper: "equalTo" }];
      if (barcode) query.push({ key: "barcode", value: barcode, oper: "equalTo" });
      params.query = this.getQuery(query);
      return this.$api.getStockItemList({ params });
    },
    getQuery(query) {
      let filters = query.reduce((prev, item) => {
        let filter = Filter.attribute(item.key)[item.oper](item.value);
        prev.push(filter);
        return prev;
      }, []);
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    },
    async getCommodityDetail() {
      let ret = await this.$api.getStockItemDetail({
        placeholder: {
          id: this.$route.params.id
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      if (!ret.data.attachments) {
        ret.data.attachments = [];
      } else {
        let urls = ret.data.attachments.map(item => item.attachmentUrl);
        let authUrl = await this.$utils.getAuthFileUrl(urls);
        authUrl.forEach((item, idx) => {
          ret.data.attachments[idx].url = authUrl[idx];
        });
      }

      this.detail = ret.data;
      this.detail.statusLabel = find(this.statusColumns, item => item.value == this.detail.status).label;
      if (this.$route.params.id) {
        this.getStockItemType();
        this.getDictsList();
        this.getWarehouseList();
      }
    },
    setStockItem() {
      let params = this.detail;
      return this.$api.setStockItem({
        params,
        placeholder: {
          id: this.$route.params.id
        }
      });
    },
    async getWarehouseList() {
      let params = {
        useType: "WAREHOUSE_MANAGER"
      };
      let ret = await this.$api.getWarehouseList({ params });
      if (ret.code != SUCCESS_CODE) return;
      this.warehouseColumns = ret.data;
      if (this.$route.params.id) {
        let item = find(this.warehouseColumns, item => item.id == this.detail.warehouseId);
        this.detail.warehouse = item.name;
      }
    },
    async getDictsList() {
      let query = "name[sin]计量单位";
      let ret = await this.$api.getDictsList({
        params: {
          query,
          page: false
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.unitColumns = ret.data["计量单位"][0].dictOptions;
    },
    addStockItem() {
      let params = this.detail;
      return this.$api.addStockItem({ params });
    },
    deleteStockItem() {
      let ids = [this.$route.params.id];
      return this.$api.deleteStockItem({
        params: {
          ids: ids.join(",")
        }
      });
    },
    radioChange(arg) {
      let item = find(this.flatCommodityType, item => item.id == arg);
      this.commodityType.text = item.name;
    },
    commodityTypeCancel() {
      this.showCommodityType = false;
    },
    commodityTypeConfirm() {
      this.detail.stockItemCategoryId = this.commodityType.id;
      this.detail.stockItemCategory = this.commodityType.text;
      this.showCommodityType = false;
    },
    unitConfirm(arg) {
      this.detail.measuringUnit = arg.value;
      this.showUnit = false;
    },
    warehouseConfirm(arg) {
      this.detail.warehouse = arg.name;
      this.detail.warehouseId = arg.id;
      this.showWarehouse = false;
    },
    statusConfirm(arg) {
      this.detail.statusLabel = arg.label;
      this.detail.status = arg.value;
      this.showStatus = false;
    },
    async onSubmit() {
      if (this.$route.params.id) {
        let ret = await this.setStockItem();
        if (ret.code != SUCCESS_CODE) return;
        this.addStockItemAttachments(this.$route.params.id);
        this.$utils.success("修改成功");
      } else {
        let ret = await this.addStockItem();
        if (ret.code != SUCCESS_CODE) return;
        this.addStockItemAttachments(ret.data.id);
        this.$utils.success("新增成功");
      }

      setTimeout(async () => {
        await this.$router.go(-1);

        setTimeout(() => {
          window.scrollTo(0, 0);
          this.$parent.$refs[window.currentRoute].init();
        }, 500);
      }, 750);
    },
    handleCoverCheckboxClick(file) {
      setTimeout(() => {
        console.log("file", file.isCover);
        let idx = this.detail.attachments.findIndex(item => {
          return item.attachmentUrl == file.attachmentUrl;
        });

        let stockItemId = this.$route.params.id;
        this.$set(this.detail.attachments, idx, file);
        if (stockItemId) {
          this.saveStockItemAttachments(stockItemId, file);
        }

        // 取消勾选封面
        // 如果是勾选，则请取消其他勾选封面
        if (!file.isCover) return;
        this.detail.attachments.forEach((item, idx) => {
          if (!item.isCover || file.attachmentUrl == item.attachmentUrl) return;
          item.isCover = false;
          this.$set(this.detail.attachments, idx, item);
          if (stockItemId) {
            this.saveAssetsAttachments(stockItemId, item);
          }
        });
      });
    },
    async saveStockItemAttachments(stockItemId, attachmentInfo) {
      if (!stockItemId || !attachmentInfo.id) return;
      let params = pick(attachmentInfo, ["id", "attachmentUrl", "status", "type", "suffix", "isCover"]);
      let ret = await this.$api.saveStockItemAttachmentById({
        params,
        placeholder: {
          stockItemId
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.$utils.success("修改成功");
    },
    async addStockItemAttachments(stockItemId) {
      let params = this.detail.attachments
        .filter(item => !item.id)
        .map(item => {
          return pick(item, ["attachmentUrl", "status", "type", "suffix", "isCover"]);
        });
      if (!params) return true;
      let ret = await this.$api.addStockItemAttachments({
        params,
        placeholder: {
          stockItemId
        }
      });
      if (ret.code != SUCCESS_CODE) return;

      ret.data.forEach(item => {
        let idx = this.detail.attachments.findIndex(it => {
          return item.attachmentUrl == it.attachmentUrl;
        });
        if (idx == -1) return;
        this.detail.attachments[idx].id = item.id;
      });
      return true;
    },
    deleteCommodity() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定删除?"
        })
        .then(async () => {
          let ret = await this.deleteStockItem();
          if (ret.code != SUCCESS_CODE) return;
          this.$utils.success("删除成功");

          setTimeout(async () => {
            await this.$router.go(-1);

            setTimeout(() => {
              window.scrollTo(0, 0);
              this.$parent.$refs[window.currentRoute].init();
            }, 500);
          }, 750);
        })
        .catch(() => {});
    },
    onFail(arg) {
      arg.errors.forEach(item => {
        this.$notify({ message: item.message, type: "warning" });
      });
    },
    getCode() {
      return new Promise(resolve => {
        wx.scanQRCode({
          needResult: 1,
          scanType: ["qrCode", "barCode"],
          success: res => {
            var result = res.resultStr;
            var code = result.split(",")[1];
            resolve(code);
          }
        });
      });
    },
    scanAdd() {
      this.getCode().then(async code => {
        let payload = {
          barcode: code
        };
        let ret = await this.getStockItemList(payload);
        if (ret.code != SUCCESS_CODE) return;
        let data = ret.data;
        if (!data.length) {
          this.addCommodity(code);
        } else {
          this.$utils.warning("已存在该物品");
        }
      });
    },
    async addCommodity(barcode) {
      let is69 = /^69/g.test(barcode);
      let commodityInfo;
      if (is69) {
        let ret = await this.getStockItemByBarCode(barcode);
        if (ret.code != SUCCESS_CODE) return this.$utils.warning("数据查询为空");
        let data = ret.data;
        let { productName, type, manufacturerName } = data.productDataRecords[0];
        commodityInfo = {
          name: productName,
          specifications: type,
          manufacturer: manufacturerName,
          barcode
        };
      } else {
        commodityInfo = {
          barcode
        };
      }
      assign(this.detail, commodityInfo);
    },

    beforeRead(file) {
      function isOverSize(file) {
        const maxSize = 10 * 1024 * 1024;
        return file.size >= maxSize;
      }

      if (!["image/jpeg", "image/jpg", "image/gif", "image/png"].includes(file.type)) {
        this.$utils.warning("请上传格式图片");
        return false;
      }

      if (isOverSize(file)) {
        this.$utils.warning("文件不能超过5M");
        return false;
      }

      file.isCover = false;

      return true;
    },
    async afterRead(file, detail) {
      let idx = detail.index;
      file.status = "uploading";
      file.message = "上传中...";
      file.isCover = false;

      console.log(file, detail);

      let formData = new FormData();
      formData.append("file", file.file);
      formData.append("type", "ITEM");
      let ret = await this.$api.postAppendixFilePath({
        params: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      if (ret.code != SUCCESS_CODE) {
        file.status = "failed";
        file.message = "上传失败";
        return;
      }

      let url = ret.data;
      let authUrl = await this.$utils.getAuthFileUrl(url);

      file.status = "done";
      file.message = "";

      this.$set(this.detail.attachments, idx, {
        id: "",
        status: "ENABLE",
        type: "IMAGE",
        suffix: "",
        isCover: false,
        attachmentUrl: url,
        url: authUrl[0]
      });
    },
    async beforeDelete(file, detail) {
      let idx = detail.index;
      let target = this.detail.attachments[idx];
      await this.$api.deleteAppendixes({
        placeholder: { url: target.attachmentUrl }
      });

      if (!target.id || !this.$route.params.id) return true;
      let ret = await this.$api.handleRemoveAttachment({
        placeholder: {
          stockItemId: this.$route.params.id,
          ids: target.id
        }
      });
      if (ret.code != SUCCESS_CODE) return true;
      this.$utils.success("删除成功");
      return true;
    }
  },
  mounted() {
    wxapi.ready(["scanQRCode"]).then(res => {
      console.log(res);
    });
  },
  created() {
    if (this.$route.params.id) {
      this.getCommodityDetail();
    } else {
      this.getStockItemType();
      this.getDictsList();
      this.getWarehouseList();
    }
  }
};
</script>
<style lang="less">
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
.cover-checkbox {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  padding: 3px;
  color: #fff;
}
.van-checkbox__label {
  color: #fff;
}
</style>
